//后台
const backend = [
    //会员列表
    {
        path: '/backend/memberindex',
        name: 'BackendMemberIndex',
        component: (resolve) => require(['@/views/backend/member/memberindex.vue'], resolve)
    },
    //得力账号列表
    {
        path: '/backend/memberindexv2',
        name: 'BackendMemberIndexV2',
        component: (resolve) => require(['@/views/backend/member/accounttable.vue'], resolve)
    },
    //短信验证码列表
    {
        path: '/backend/mobiletable',
        name: 'BackendMobileTable',
        component: (resolve) => require(['@/views/backend/member/mobiletable.vue'], resolve)
    },
    //授权店铺列表
    {
        path: '/backend/shopsindex',
        name: 'BackendShopsIndex',
        component: (resolve) => require(['@/views/backend/member/shopsindex.vue'], resolve)
    },
    //会员购买记录
    {
        path: '/backend/memberbuyindex',
        name: 'BackendMemberBuyIndex',
        component: (resolve) => require(['@/views/backend/member/memberbuyindex.vue'], resolve)
    },
    //用户操作日志
    {
        path: '/backend/memberopindex',
        name: 'BackendMemberOpIndex',
        component: (resolve) => require(['@/views/backend/member/memberopindex.vue'], resolve)
    },
    //运营管理
    //套餐列表
    {
        path: '/backend/setmealindex',
        name: 'BackendSetMealIndex',
        component: (resolve) => require(['@/views/backend/operate/setmealindex.vue'], resolve)
    },
    //卡密管理
    {
        path: '/backend/cardmiindex',
        name: 'BackendCardMiIndex',
        component: (resolve) => require(['@/views/backend/operate/cardmiindex.vue'], resolve)
    },
    //卡密领取
    {
        path: '/backend/receiveindex',
        name: 'BackendReceiveIndex',
        component: (resolve) => require(['@/views/backend/operate/receiveindex.vue'], resolve)
    },
    //chatgpt卡密领取
    {
        path: '/backend/chatgpt/receiveindex',
        name: 'BackendChatgptReceiveIndex',
        component: (resolve) => require(['@/views/backend/chatgpt/receiveindex.vue'], resolve)
    },
    //chatgpt池子
    {
        path: '/backend/chatgpt/chatgptpoolindex',
        name: 'BackendChatgptPoolIndex',
        component: (resolve) => require(['@/views/backend/chatgpt/chatgptpoolindex.vue'], resolve)
    },
    //用户访问
    {
        path: '/backend/visiindex',
        name: 'BackendVisiIndex',
        component: (resolve) => require(['@/views/backend/operate/visiindex.vue'], resolve)
    },
    //用户访问明细
    {
        path: '/backend/visidetail',
        name: 'BackendVisiDetail',
        component: (resolve) => require(['@/views/backend/operate/visidetail.vue'], resolve)
    },
    //系统管理
    //菜单管理
    {
        path: '/backend/menuindex',
        name: 'BackendMenuIndex',
        component: (resolve) => require(['@/views/backend/system/menuindex.vue'], resolve)
    },
    //权限管理
    {
        path: '/backend/roleindex',
        name: 'BackendRoleIndex',
        component: (resolve) => require(['@/views/backend/system/roleindex.vue'], resolve)
    },
    //管理员列表
    {
        path: '/backend/adminindex',
        name: 'BackendAdminIndex',
        component: (resolve) => require(['@/views/backend/system/adminindex.vue'], resolve)
    },
    //采购买号列表
    {
        path: '/buyer/adminindex',
        name: 'BuyerAdminIndex',
        component: (resolve) => require(['@/views/backend/buyer/adminindex.vue'], resolve)
    },
    //地址黑名单
    {
        path: '/shippingblack/adminindex',
        name: 'ShippingBlackIndex',
        component: (resolve) => require(['@/views/backend/shippingblack/adminindex.vue'], resolve)
    },
    //系统模块
    {
        path: '/backend/systemmodule',
        name: 'BackendSystemModule',
        component: (resolve) => require(['@/views/backend/system/systemmodule.vue'], resolve)
    },
    //chatgpt模块
    {
        path: '/backend/chatgpt/systemmodule',
        name: 'BackendChatgptSystemModule',
        component: (resolve) => require(['@/views/backend/chatgpt/systemmodule.vue'], resolve)
    },
    //数据库
    {
        path: '/backend/databaseindex',
        name: 'BackendDatabaseIndex',
        component: (resolve) => require(['@/views/backend/system/databaseindex.vue'], resolve)
    },
    //语言包
    {
        path: '/backend/langindex',
        name: 'BackendLangIndex',
        component: (resolve) => require(['@/views/backend/system/langindex.vue'], resolve)
    },
    //配置
    {
        path: '/backend/configindex',
        name: 'BackendConfigIndex',
        component: (resolve) => require(['@/views/backend/system/configindex.vue'], resolve)
    },
    //系统监控
    //店铺监控
    {
        path: '/backend/shopmonitorindex',
        name: 'BackendShopMonitorIndex',
        component: (resolve) => require(['@/views/backend/monitor/shopmonitor.vue'], resolve)
    },
    //店铺管理
    {
        path: '/backend/taobaoshopindex',
        name: 'BackendTaobaoShopIndex',
        component: (resolve) => require(['@/views/backend/shop/shoptab.vue'], resolve)
    },
    //店铺管理（新）
    {
        path: '/backend/taobaoshopindexnew',
        name: 'BackendTaobaoShopIndexNew',
        component: (resolve) => require(['@/views/backend/shop/shoptabnew.vue'], resolve)
    },
    {
        //店铺池
        path: '/backend/taobaoshoppond',
        name: 'BackendTaobaoShopPond',
        component: (resolve) => require(['@/views/backend/shop/shoppond.vue'], resolve)
    },
    {
        //店铺池修改记录
        path: '/backend/taobaoshoppondeditlog',
        name: 'BackendTaobaoShopPondEditLog',
        component: (resolve) => require(['@/views/backend/shop/shoptablepondeditlog.vue'], resolve)
    },
    {
        //店铺分组
        path: '/backend/taobaoshopgroupindex',
        name: 'BackendTaobaoShopGroupIndex',
        component: (resolve) => require(['@/views/backend/shop/grouptable.vue'], resolve)
    },
    {
        //店铺每日数据报表
        path: '/backend/shopbydatetable',
        name: 'BackendShopByDateTable',
        component: (resolve) => require(['@/views/backend/shop/shopbydatetab.vue'], resolve)
    },
    {
        //商品流量
        path: '/backend/llrktable',
        name: 'BackendLlrkTable',
        component: (resolve) => require(['@/views/backend/shop/llrktable.vue'], resolve)
    },
    {
        //关键词来源
        path: '/backend/goodsourcetable',
        name: 'BackendGoodSourceTable',
        component: (resolve) => require(['@/views/backend/shop/goodsourcetable.vue'], resolve)
    },
    {
        //店铺订单列表
        path: '/backend/ordertable',
        name: 'BackendOrderTable',
        component: (resolve) => require(['@/views/backend/shop/ordertable.vue'], resolve)
    },
    {
        //店铺采购单列表
        path: '/backend/purchasetab',
        name: 'BackendPurchaseTab',
        component: (resolve) => require(['@/views/backend/shop/purchasetab.vue'], resolve)
    },
    {
        //淘宝主账号
        path: '/backend/mastershoptable',
        name: 'BackendMasterShopTable',
        component: (resolve) => require(['@/views/backend/shop/mastershoptable.vue'], resolve)
    },
    {
        //人员管理
        path: '/backend/personneltable',
        name: 'BackendPersonnelTable',
        component: (resolve) => require(['@/views/backend/shop/personneltable.vue'], resolve)
    }, {
        //我的工单
        path: '/backend/mytickets',
        name: 'BackendMyTicketsTable',
        component: (resolve) => require(['@/views/backend/tickets/myticketstable.vue'], resolve)
    }, {
        //选品管理
        path: '/backend/goodsaudit',
        name: 'BackendGoodsAuditTable',
        component: (resolve) => require(['@/views/backend/goodsaudit/goodsaudittable.vue'], resolve)
    }, {
        //财务管理-提现申请列表
        path: '/backend/applymoney',
        name: 'BackendShopApplyMoney',
        component: (resolve) => require(['@/views/backend/shopapplymoney/applymoneylist.vue'], resolve)
    }, {
        //财务管理-保证金充值账号
        path: '/backend/applybondaccount',
        name: 'BackendShopApplyBondAccount',
        component: (resolve) => require(['@/views/backend/shopapplybond/applybondaccountlist.vue'], resolve)
    }, {
        //财务管理-转账收款账号
        path: '/backend/applymoneyaccount',
        name: 'BackendShopApplyMoneyAccount',
        component: (resolve) => require(['@/views/backend/shopapplymoney/applymoneyaccountlist.vue'], resolve)
    }, {
        //财务管理-保证金列表
        path: '/backend/applybond',
        name: 'BackendShopApplyBond',
        component: (resolve) => require(['@/views/backend/shopapplybond/applymoneylist.vue'], resolve)
    },
    {
        //所有订单
        path: '/backend/allorders',
        name: 'BackendAllOrders',
        component: (resolve) => require(['@/views/backend/shop/allordertab.vue'], resolve)
    },
    {
        //1688订单
        path: '/backend/orders1688',
        name: 'BackendOrders1688',
        component: (resolve) => require(['@/views/backend/shop/1688ordertab.vue'], resolve)
    },
    {
        //货源地址
        path: '/backend/goodspublish',
        name: 'BackendGoodsPublish',
        component: (resolve) => require(['@/views/backend/shop/goodspublishtable.vue'], resolve)
    },
    {
        //1688商品关联
        path: '/backend/1688guanliantable',
        name: 'Backend1688GuanLianTable',
        component: (resolve) => require(['@/views/backend/shop/1688guanliantable.vue'], resolve)
    },
    {
        //搜索分析
        path: '/backend/searchanalyze',
        name: 'BackendSearchAnalyze',
        component: (resolve) => require(['@/views/backend/searchanalysis/documenttable.vue'], resolve)
    },
    {
        //搜索明细
        path: '/backend/searchanalyzeview',
        name: 'BackendSearchAnalyzeView',
        component: (resolve) => require(['@/views/backend/searchanalysis/tabs.vue'], resolve)
    },
    {
        //运营搜索明细
        path: '/backend/yunyingsearchanalyzeview',
        name: 'BackendYunYingSearchAnalyzeView',
        component: (resolve) => require(['@/views/backend/searchanalysis/yunyingtabs.vue'], resolve)
    },
    {
        //品牌词库
        path: '/backend/brand',
        name: 'BackendBrand',
        component: (resolve) => require(['@/views/backend/searchanalysis/brandtable.vue'], resolve)
    },
    {
        //分词词库
        path: '/backend/participle',
        name: 'BackendParticiple',
        component: (resolve) => require(['@/views/backend/searchanalysis/participletable.vue'], resolve)
    },
    {
        //运营数据汇总
        path: '/backend/datareport',
        name: 'BackendDataReport',
        component: (resolve) => require(['@/views/backend/searchanalysis/datareport.vue'], resolve)
    },
    {
        //运营数据汇总v2
        path: '/backend/datareportv2',
        name: 'BackendDataReportV2',
        component: (resolve) => require(['@/views/backend/searchanalysis/datareportv2.vue'], resolve)
    },
    {
        //搜索词报告
        path: '/backend/keywordreporttable',
        name: 'BackendKeywordReportTable',
        component: (resolve) => require(['@/views/backend/task/keywordreporttable.vue'], resolve)
    },
    {
        //运营数据汇总搜索词结果
        path: '/backend/datareportmyoperategoodspostable',
        name: 'BackendDataReportMyOperateGoodsPosTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/datareportmyoperategoodspostable.vue'], resolve)
    },
    {
        //运营搜索词库
        path: '/backend/operatesearchanalyze',
        name: 'BackendOperateSearchAnalyze',
        component: (resolve) => require(['@/views/backend/searchanalysis/operatedocumenttabs.vue'], resolve)
    },
    {
        //运营下单
        path: '/backend/operatesearchanalyzev2',
        name: 'BackendOperateSearchAnalyzeV2',
        component: (resolve) => require(['@/views/backend/searchanalysis/operatedocumenttabsv2.vue'], resolve)
    },
    {
        //类目品牌搜索词
        path: '/backend/taobaocategorydocumenttable',
        name: 'BackendTaoBaoCategoryDocumentTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/taobaocategorydocumenttable.vue'], resolve)
    },
    {
        //买号导入
        path: '/backend/shopbuyertable',
        name: 'BackendShopBuyerTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/shopbuyertable.vue'], resolve)
    },
    {
        //我的买号
        path: '/backend/shopbuyeryunyingtable',
        name: 'BackendShopBuyerYunYingTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/shopbuyeryunyingtable.vue'], resolve)
    },
    {
        //系统支付宝号
        path: '/backend/sysalipaytable',
        name: 'BackendSysAliPayTable',
        component: (resolve) => require(['@/views/backend/task/sysalipaytable.vue'], resolve)
    },
    {
        //支付宝打款
        path: '/backend/alibabashopbuyeralipaytranferreportitemstable',
        name: 'BackendAliBabaShopBuyerAlipayTranferReportItemsTable',
        component: (resolve) => require(['@/views/backend/task/alibabashopbuyeralipaytranferreportitemstable.vue'], resolve)
    },
    {
        //机器询问内容
        path: '/backend/sysaliasktable',
        name: 'BackendSysAliAskTable',
        component: (resolve) => require(['@/views/backend/task/sysaliasktable.vue'], resolve)
    },
    {
        //手机警报
        path: '/backend/buyerlogtable',
        name: 'BackendBuyerLogTable',
        component: (resolve) => require(['@/views/backend/task/buyerlogtable.vue'], resolve)
    },
    {
        //买号报表
        path: '/backend/shopbuyerreporttabs',
        name: 'BackendShopBuyerReportTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/shopbuyerreporttabs.vue'], resolve)
    },
    {
        //计划模板
        path: '/backend/shopbuyertemplatetable',
        name: 'BackendShopBuyerTemplateTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/shopbuyertemplatetable.vue'], resolve)
    },
    {
        //计划明细
        path: '/backend/shopbuyertemplateitemtable',
        name: 'BackendShopBuyerTemplateItemTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/shopbuyertemplateitemtable.vue'], resolve)
    },
    {
        //店铺主账号
        path: '/backend/taodaxiang',
        name: 'BackendTaoDaXiang',
        component: (resolve) => require(['@/views/backend/searchanalysis/taodaxiangtable.vue'], resolve)
    },
    {
        //运营搜索词结果
        path: '/backend/operatesearchanalyzeresult',
        name: 'BackendOperateSearchAnalyzeResult',
        component: (resolve) => require(['@/views/backend/searchanalysis/operatetabs.vue'], resolve)
    },
    {
        //运营搜索词结果
        path: '/backend/myoperatesearchanalyzeresult',
        name: 'BackendMyOperateSearchAnalyzeResult',
        component: (resolve) => require(['@/views/backend/searchanalysis/myoperatetabs.vue'], resolve)
    },
    {
        //批量加购下单
        path: '/backend/batchoperategoodspostable',
        name: 'BackendBatchOperateGoodsPosTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/batchoperategoodspostable.vue'], resolve)
    },
    {
        //过滤词库
        path: '/backend/delkeyword',
        name: 'BackendDelKeyword',
        component: (resolve) => require(['@/views/backend/searchanalysis/delkeywordtable.vue'], resolve)
    },
    {
        //商品库
        path: '/backend/goods',
        name: 'BackendGoods',
        component: (resolve) => require(['@/views/backend/searchanalysis/goodstable.vue'], resolve)
    },
    {
        //下单页面
        path: '/backend/cart',
        name: 'BackendCart',
        component: (resolve) => require(['@/views/backend/searchanalysis/cart.vue'], resolve)
    },
    {
        //店铺库
        path: '/backend/norepeatshops',
        name: 'BackendNoRepeatShops',
        component: (resolve) => require(['@/views/backend/searchanalysis/shoptable.vue'], resolve)
    },
    {
        //店铺主账号
        path: '/backend/mastershop',
        name: 'BackendMasterShop',
        component: (resolve) => require(['@/views/backend/shop/mastertable.vue'], resolve)
    },
    {
        //余额管理
        path: '/backend/balancemanagement',
        name: 'BalanceManagement',
        component: (resolve) => require(['@/views/backend/balancemanagement/mastertable.vue'], resolve)
    },
    {
        //店铺指标
        path: '/backend/shopindicators',
        name: 'BackendShopIndicators',
        component: (resolve) => require(['@/views/backend/shop/shopindicatorstable.vue'], resolve)
    },
    {
        //违规列表
        path: '/backend/punishtable',
        name: 'BackendPunishTable',
        component: (resolve) => require(['@/views/backend/shop/punishtable.vue'], resolve)
    },
    {
        //违约列表
        path: '/backend/punishtablecomplaint',
        name: 'BackendPunishTableComplaint',
        component: (resolve) => require(['@/views/backend/shop/punishcomplainttable.vue'], resolve)
    },
    {
        //采购单核对
        path: '/backend/purchasesinglecorepair',
        name: 'BackendPurchaseSingleCorePair',
        component: (resolve) => require(['@/views/backend/shop/purchasesinglecorepair.vue'], resolve)
    },
    {
        //周报导出
        path: '/backend/adminweeklyreportv2',
        name: 'BackendAdminWeeklyReportV2',
        component: (resolve) => require(['@/views/backend/shop/adminweeklyreportv2.vue'], resolve)
    },
    {
        //物流列表
        path: '/backend/delivertable',
        name: 'BackendDeliverTable',
        component: (resolve) => require(['@/views/backend/deliver/delivertable.vue'], resolve)
    },
    {
        //物流礼品列表
        path: '/backend/delivergiftable',
        name: 'BackendDeliverGifTable',
        component: (resolve) => require(['@/views/backend/deliver/delivergiftable.vue'], resolve)
    },
    {
        //快递发货
        path: '/backend/fahuotable',
        name: 'BackendFaHuoTable',
        component: (resolve) => require(['@/views/backend/deliver/fahuotable.vue'], resolve)
    },
    {
        //类目品牌词数据抓取
        path: '/backend/brandgoodstable',
        name: 'BackendBrandGoodsTable',
        component: (resolve) => require(['@/views/backend/dataanalysis/brandgoodstable.vue'], resolve)
    },
    {
        //类目品牌分析
        path: '/backend/brandandcatetable',
        name: 'BackendBrandAndCateTable',
        component: (resolve) => require(['@/views/backend/dataanalysis/brandandcatetable.vue'], resolve)
    },
    {
        //品牌库
        path: '/backend/brandtable',
        name: 'BackendBrandTable',
        component: (resolve) => require(['@/views/backend/dataanalysis/brandtable.vue'], resolve)
    },
    {
        //品牌库（新）
        path: '/backend/brand/brandtable',
        name: 'BackendBrandBrandTable',
        component: (resolve) => require(['@/views/backend/brand/brandtable.vue'], resolve)
    },
    {
        //品牌库案件记录
        path: '/backend/brandcase/brandtable',
        name: 'BackendBrandCaseBrandTable',
        component: (resolve) => require(['@/views/backend/brandcase/brandtable.vue'], resolve)
    },{
        //商品链接库
        path: '/backend/goodslibrary/goodstable',
        name: 'BackendGoodsLibraryTable',
        component: (resolve) => require(['@/views/backend/goodslibrary/goodstable.vue'], resolve)
    },
    {
        //品牌类目市场分析
        path: '/backend/sycmsearchresulttable',
        name: 'BackendSycmSearchResultTable',
        component: (resolve) => require(['@/views/backend/dataanalysis/sycmsearchresulttable.vue'], resolve)
    },
    {
        //财务
        path: '/backend/reconciliationtable',
        name: 'BackendReconciliationTable',
        component: (resolve) => require(['@/views/backend/finance/reconciliationtable.vue'], resolve)
    },
    {
        //财务对账
        path: '/backend/startreconciliation',
        name: 'BackendStartReconciliation',
        component: (resolve) => require(['@/views/backend/finance/startreconciliation.vue'], resolve)
    },
    {
        //运营对账
        path: '/backend/operationalreconciliationtable',
        name: 'BackendOperationalReconciliationTable',
        component: (resolve) => require(['@/views/backend/finance/operationalreconciliationtablenew.vue'], resolve)
    },
    {
        //店铺支付宝流水
        path: '/backend/alipayflowingtable',
        name: 'BackendAlipayFlowingTable',
        component: (resolve) => require(['@/views/backend/finance/alipayflowingtable.vue'], resolve)
    },
    {
        //买号支付宝流水
        path: '/backend/alipaybuyerflowingtable',
        name: 'BackendAlipayBuyerFlowingTable',
        component: (resolve) => require(['@/views/backend/finance/alipaybuyerflowingtable.vue'], resolve)
    },
    {
        //淘宝交易流水
        path: '/backend/taobaoflowingtable',
        name: 'BackendTaobaoFlowingTable',
        component: (resolve) => require(['@/views/backend/finance/taobaoflowingtable.vue'], resolve)
    },
    {
        //排除名单
        path: '/backend/shopexclusiontable',
        name: 'BackendShopExclusionTable',
        component: (resolve) => require(['@/views/backend/task/shopexclusiontable.vue'], resolve)
    },
    {
        //店铺列表
        path: '/backend/taskshoplist',
        name: 'BackendTaskShopList',
        component: (resolve) => require(['@/views/backend/task/taskshoptable.vue'], resolve)
    },
    {
        //店铺列表
        path: '/backend/taskshoplistv2',
        name: 'BackendTaskShopListV2',
        component: (resolve) => require(['@/views/backend/task/taskshoptablev2.vue'], resolve)
    },
    {
        //宝贝链接列表
        path: '/backend/taskgoodslist',
        name: 'BackendTaskGoodsList',
        component: (resolve) => require(['@/views/backend/task/taskgoodstable.vue'], resolve)
    },
    {
        //关联评价列表
        path: '/backend/taskgoodscommenttable',
        name: 'BackendTaskGoodsCommentTable',
        component: (resolve) => require(['@/views/backend/task/taskgoodscommenttable.vue'], resolve)
    },
    //chatgpt卡密管理
    {
        path: '/backend/chatgpt/cardmiindex',
        name: 'BackendChatgptCardMiIndex',
        component: (resolve) => require(['@/views/backend/chatgpt/cardmiindex.vue'], resolve)
    },
    {
        //保证金详细列表
        path: '/backend/taobaoshopbond',
        name: 'BackendTaobaoShopBond',
        component: (resolve) => require(['@/views/backend/shopbond/shopbond.vue'], resolve)
    },
    //待办事项
    {
        path: '/backend/mytodotabs',
        name: 'BackendMyToDoTabs',
        component: (resolve) => require(['@/views/backend/todolist/mytodotabs.vue'], resolve)
    },
    //选品
    {
        path: '/backend/selection',
        name: 'BackendSelection',
        component: (resolve) => require(['@/views/backend/selection/selectiontitletable.vue'], resolve)
    },
    //选品商品
    {
        path: '/backend/selectiongood',
        name: 'BackendSelectionGood',
        component: (resolve) => require(['@/views/backend/selection/selectiongoodtable.vue'], resolve)
    },
    //选品商品评价
    {
        path: '/backend/selectiongoodcomment',
        name: 'BackendSelectionGoodComment',
        component: (resolve) => require(['@/views/backend/selection/selectiongoodscommenttable.vue'], resolve)
    },
    //运营选品
    {
        path: '/backend/selectionyunying',
        name: 'BackendSelectionYunYing',
        component: (resolve) => require(['@/views/backend/selection/yunyingselectiontitletable.vue'], resolve)
    },
    //运营选品商品
    {
        path: '/backend/selectionyunyinggood',
        name: 'BackendSelectionYunYingGood',
        component: (resolve) => require(['@/views/backend/selection/yunyingselectiongoodtable.vue'], resolve)
    },
    //运营选品商品评价抓取队列
    {
        path: '/backend/selectionyunyinggoodcomment',
        name: 'BackendSelectionYunYingGoodComment',
        component: (resolve) => require(['@/views/backend/selection/yunyingselectiongoodcommenturlqueuetable.vue'], resolve)
    },
    //运营选品搜索词
    {
        path: '/backend/selectionkeywordtable',
        name: 'BackendSelectionKeywordTable',
        component: (resolve) => require(['@/views/backend/selection/selectionkeywordtable.vue'], resolve)
    },
    //运营选品搜索词
    {
        path: '/backend/selectionkeywordtableyunying',
        name: 'BackendSelectionKeywordTableYunYing',
        component: (resolve) => require(['@/views/backend/selection/yunyingselectionkeywordtable.vue'], resolve)
    },
    {
        //新分类队列
        path: '/backend/newcatequeue',
        name: 'BackendNewCateQueue',
        component: (resolve) => require(['@/views/backend/dataanalysis/newcatequeue.vue'], resolve)
    },
    {
        //新分类队列明细
        path: '/backend/newcatedetail',
        name: 'BackendNewCateDetail',
        component: (resolve) => require(['@/views/backend/dataanalysis/newcatedetail.vue'], resolve)
    },
    {
        //品牌生意参谋明细
        path: '/backend/branddetail',
        name: 'BackendBrandDetail',
        component: (resolve) => require(['@/views/backend/dataanalysis/branddetail.vue'], resolve)
    },
    {
        //新分类列表
        path: '/backend/newcatelist',
        name: 'BackendNewCateList',
        component: (resolve) => require(['@/views/backend/dataanalysis/newcatelist.vue'], resolve)
    },
    //地区列表
    {
        path: '/backend/areatable',
        name: 'BackendAreaTable',
        component: (resolve) => require(['@/views/backend/area/areatable.vue'], resolve)
    },
    //生意参谋数据查询模版
    {
        path: '/backend/tickeycategorytable',
        name: 'BackendTickeyCategoryTable',
        component: (resolve) => require(['@/views/backend/tickeycategory/tickeycategorytable.vue'], resolve)
    },
    //排除的搜索词
    {
        path: '/backend/notkeywordtable',
        name: 'BackendNotKeywordTable',
        component: (resolve) => require(['@/views/backend/notkeyword/notkeywordtable.vue'], resolve)
    },
    //账号流水
    {
        path: '/backend/flowingtable',
        name: 'BackendFlowingTable',
        component: (resolve) => require(['@/views/backend/flowing/flowingtable.vue'], resolve)
    },
    //关键词队列
    {
        path: '/backend/keywordqueue',
        name: 'BackendKeywordQueue',
        component: (resolve) => require(['@/views/backend/dataanalysis/keywordqueue.vue'], resolve)
    },
    // 搜索关键词结果
    {
        path: '/backend/keywordresult',
        name: 'BackendKeywordResult',
        component: (resolve) => require(['@/views/backend/dataanalysis/keywordresult.vue'], resolve)
    },
    // 淘宝关键词搜索分析
    {
        path: '/backend/taskgoodskeywords',
        name: 'BackendTaskGoodsKeywords',
        component: (resolve) => require(['@/views/backend/task/taskgoodskeywords.vue'], resolve)
    },
    // 客服列表(客服角色)
    {
        path: '/backend/agent',
        name: 'BackendAgentTable',
        component: (resolve) => require(['@/views/backend/agent/index/agenttable.vue'], resolve)
    },
    //客服店铺聊天记录
    {
        path: '/backend/agent_chat',
        name: 'BackendAgentChatTable',
        component: (resolve) => require(['@/views/backend/agent/chat/agenttable.vue'], resolve)
    },
    {
        //客服聊天号导入
        path: '/backend/agent_chat_no',
        name: 'BackendAgentChatNoTable',
        component: (resolve) => require(['@/views/backend/agent/chatno/agentchatnotable.vue'], resolve)
    },
    {
        //公共评价类目
        path: '/backend/taskpubliccommentclass',
        name: 'BackendTaskPublicCommentClass',
        component: (resolve) => require(['@/views/backend/task/publiccommentclass.vue'], resolve)
    },
    {
        //公共评价列表
        path: '/backend/taskpubliccomment',
        name: 'BackendTaskPublicComment',
        component: (resolve) => require(['@/views/backend/task/publiccomment.vue'], resolve)
    },
    {
        //账户明细回调示例
        path: '/backend/saveaccountdetails',
        name: 'BackendSaveAccountDetails',
        component: (resolve) => require(['@/views/backend/shop/saveaccountdetailstable.vue'], resolve)
    },
    {
        //保险金账单明细
        path: '/backend/insurancedetails',
        name: 'BackendInsuranceDetails',
        component: (resolve) => require(['@/views/backend/shop/insurancedetailstable.vue'], resolve)
    },
    {
        //阿里妈妈明细回调示例
        path: '/backend/savealimamadetails',
        name: 'BackendSaveAliMamaDetails',
        component: (resolve) => require(['@/views/backend/shop/savealimamadetailstable.vue'], resolve)
    },
    {
        //淘宝滑块轨迹录入
        path: '/backend/x5sectable',
        name: 'BackendX5SecTable',
        component: (resolve) => require(['@/views/backend/system/x5sectable.vue'], resolve)
    },
    {
        //淘宝滑块轨迹录入
        path: '/backend/commontemplatetable',
        name: 'BackendCommonTemplateTable',
        component: (resolve) => require(['@/views/backend/searchanalysis/commontemplatetable.vue'], resolve)
    },
];
export default backend;