<template>
  <div class="main-top">
    <div class="flex">
      <div class="flex ac mgr10">
        <img class="logo" :src="require('@/assets/1.png')" />
      </div>
      <div class="flex ac f24 bold mgr60">得力助手管理后台</div>
    </div>
    <div class="flex ac">
      <div class="f14 f-999 flex ac"></div>
      <div class="mgl25">
        <el-badge :is-dot="dotCount">
          <el-button @click="jumpmodule()" type="primary" icon="el-icon-bell" circle></el-button>
        </el-badge>
      </div>
      <div class="mgl25">
        <el-dropdown>
          <div class="flex ac po f12 f-primary">
            <el-avatar style="background-color: #5cadff;" icon="el-icon-download"></el-avatar>
            <span style="padding:0px 10px;">下载APK</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="qrcode">
              <YQrcode :width="200" :height="200" :src="qrcode"></YQrcode>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="mgl25" v-if="user">
        <el-dropdown @command="dropdownClick">
          <div class="flex ac po">
            <el-avatar icon="el-icon-user" v-if="user.avatar_url == ''"></el-avatar>
            <el-avatar :src="user.avatar_url" v-else></el-avatar>
            <span style="padding:0px 10px;">{{user.user_name}}</span>
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="user">个人中心</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dotCount:false,
      user: undefined,
      qrcode: document.location.protocol+"//www.21shopgroup.com/download.html?t=" + (new Date()).getTime(),
      url: {
        logout: "/sysaccount/logout",
        ticketunread: "/syssysticket/sysshopticketunread",
      },
    };
  },
  created() {
    this.loadUser();
    this.getDotCount()
  },
  methods: {
    jumpmodule: function () {
      //跳转
      this.$router.push({
        name: "BackendMyTicketsTable",
        query: {
          admin_read: 0,
        }
      });
    },
    getDotCount () {
      this.$rq.postAction(this.url.ticketunread, {}).then((rs) => {
        if (rs.code == 1) {
          this.dotCount =true
        }else {
          this.dotCount =false
        }
      })

    },
    dropdownClick: function (v) {
      if (v == "logout") {
        this.logout();
      } else if (v == "user") {
        var t = new Date().getTime();
        this.$router.push({
          name: "BackendBusiness",
          query: {
            t: t,
          },
        });
      }
    },
    loadUser: function () {
      let user = this.$func.get("user-info");
      if (user) {
        this.user = user;
      } else {
        /*
        var t = new Date().getTime();
        this.$message({
          type: "warning",
          message: "您尚未登录,请重新登录",
        });
        this.$router.push({
          name: "Login",
          query: {
            t: t,
          },
        });
        */
      }
    },
    logout: function () {
      this.$rq.postAction(this.url.logout, {}).then((rs) => {
        if (rs.code == 1) {
          this.$func.del("user-info");
          var t = new Date().getTime();
          this.$message({
            type: "success",
            message: "退出登录成功",
          });
          this.$router.push({
            name: "Login",
            query: {
              t: t,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: rs.msg,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.main-top {
  height: 60px;
  padding: 0px 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 47px;
}
.mgr60 {
  margin-right: 60px;
}
.notice > p {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  color: #409eff;
}
.pdlr {
  padding: 0px 10px;
}
.mgl25 {
  margin-left: 25px;
}
.sw {
  width: 100%;
}
</style>