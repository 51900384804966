import moment from 'moment';
export default {
    getByteLength: function (str) {
        var len = 0;
        if (str!=undefined){
            for (var i = 0; i < str.length; i++) {
                if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
                    len += 2;
                } else {
                    len++;
                }
            }
        }
        return len;
    },
    //中英文混合时,截取固定长度的最优返回字符串
    cutStr: function (str, maxLen) {
        var rstr = "";
        var i = 0;
        var cond=true;
        while (cond) {
            var tmp = rstr + str.substring(i, i + 1);
            if (this.getByteLength(tmp) < maxLen && i < str.length) {
                rstr = tmp;
            } else {
                break;
            }
            i++;
        }
        return rstr;
    },
    log: function (...arg) {
        console.log("%c控制台日志输出：", "color:#fff;font-size:14px;font-weight:bold;background-color:#409eff;border-radius: 4px;padding:0px 8px;", ...arg);
    },
    err: function (...arg) {
        console.error("=============", ...arg);
    },
    save: function (name, data) {
        localStorage.setItem(name, JSON.stringify(data));
    },
    del: function (name) {
        localStorage.removeItem(name);
    },
    get: function (name) {
        var s = localStorage.getItem(name);
        if (s) {
            return JSON.parse(s);
        }
        return;
    },
    saveS: function (name, data) {
        localStorage.setItem(name, data);
    },
    getS: function (name) {
        return localStorage.getItem(name);
    },
    ssave: function (name, data) {
        sessionStorage.setItem(name, JSON.stringify(data));
    },
    sdel: function (name) {
        sessionStorage.removeItem(name);
    },
    sget: function (name) {
        var s = sessionStorage.getItem(name);
        if (s) {
            return JSON.parse(s);
        }
        return;
    },
    ssaveS: function (name, data) {
        sessionStorage.setItem(name, data);
    },
    sgetS: function (name) {
        return sessionStorage.getItem(name);
    },
    checkMobile: function (sMobile) {
        if (!(/^1[3|4|5|8|7|9|6][0-9]\d{4,8}$/.test(sMobile))) {
            return false;
        }
        return true;
    },
    copy: function (obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    format: function (t, fmt) {
        var fm = "YYYY-MM-DD HH:mm:ss";
        if (fmt) {
            fm = fmt;
        }
        return moment(t * 1000).format(fm);
    },
    firstUpper: function (char) {
        return char.charAt(0).toUpperCase() + char.slice(1);
    },
    getAmount: function (fee, showUnit) {
        var u = this.get("user-info");
        var f = 0;
        var unit = "";
        if (u && u.show_currency == 1) {
            f = fee;
            unit = " 金币";
        } else {
            f = (fee * u.rate).toFixed(2);
            unit = " 元";
        }
        if (showUnit) {
            return f + unit
        }
        return f
    },
    //显示当前时间到分钟
    nowM: function () {
        return moment().format('YYYY-MM-DD HH:mm');
    },
    now: function () {
        return parseInt((new Date()).getTime() / 1000);
    },
    nowLong: function () {
        return (new Date()).getTime();
    },
    //格式化时间
    formatDate: function (t) {
        if (t > 0) {
            return moment(t * 1000).format('YYYY-MM-DD');
        } else {
            return "None";
        }
    },
    getYear:function(t){
        if (t > 0) {
            return moment(t * 1000).format('YYYY');
        } else {
            return "None";
        }
    },
    getMonth: function (t) {
        if (t > 0) {
            return moment(t * 1000).format('DD');
        } else {
            return "None";
        }
    },
    getDay: function (t) {
        if (t > 0) {
            return moment(t * 1000).format('MM');
        } else {
            return "None";
        }
    },
    //格式化时间
    formatDateHHmm: function (t) {
        if (t > 0) {
            return moment(t * 1000).format('YYYY-MM-DD HH:mm');
        } else {
            return "None";
        }
    },
    //格式化时间
    formatDateHHmmss: function (t) {
        if (t > 0) {
            return moment(t * 1000).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return "None";
        }
    },
    intToDate:function(t){
        if (t > 0) {
            var d = moment(t * 1000).format('YYYY-MM-DD HH:mm:ss');
            return new Date(d);
        }else{
            return new Date();
        }
    },
    //格式化时间，时间戳为long型的
    formatDateLong: function (t) {
        return moment(t).format('YYYY-MM-DD');
    },
    formatPickDate: function (t) {
        return moment(t * 1000).format('YYYY-MM-DD HH:mm');
    },
    getTime: function (date) {
        var localTime = date.getTime();
        //var localOffset = date.getTimezoneOffset() * 60 * 1000;
        var localOffset = 0;
        return parseInt((localTime + localOffset) / 1000);
    },
    getTimeByStr: function (str) {
        var start = parseInt((new Date(str + " 00:00:00")).getTime() / 1000);
        var end = parseInt((new Date(str + " 23:59:59")).getTime() / 1000);
        return [start, end];
    },
    getStartTimeByStr: function (str) {
        return parseInt((new Date(str + " 00:00:00")).getTime() / 1000);
    },
    getEndTimeByStr: function (str) {
        return parseInt((new Date(str + " 23:59:59")).getTime() / 1000);
    },
    //获得指定日期的00:00:00-23:59:59
    getFullDay: function (t) {
        var n = this.formatDate(t);
        var start = parseInt((new Date(n + " 00:00:00")).getTime() / 1000);
        var end = parseInt((new Date(n + " 23:59:59")).getTime() / 1000);
        return [start, end];
    },
    //获得指定日期的00:00:00-23:59:59
    getStartDay: function (t) {
        var n = this.formatDate(t);
        var start = parseInt((new Date(n + " 00:00:00")).getTime() / 1000);
        return start;
    },
    getEndDay: function (t) {
        var n = this.formatDate(t);
        var end = parseInt((new Date(n + " 23:59:59")).getTime() / 1000);
        return end;
    },
    getNowStart:function(){
        var n = this.formatDate(this.now());
        var start = parseInt((new Date(n + " 00:00:00")).getTime() / 1000);
        return start;
    },
    //获得指定日期的00:00:00-23:59:59
    getFullDayLong: function (t) {
        var n = this.formatDate(t);
        var start = parseInt((new Date(n + " 00:00:00")).getTime());
        var end = parseInt((new Date(n + " 23:59:59")).getTime());
        return [start, end];
    },
    
    //生成 [n,m] 的随机整数
    randomNum:function(minNum, maxNum) {
        switch (arguments.length) {
            case 1:
                return parseInt(Math.random() * minNum + 1, 10);
            case 2:
                return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
            default:
                return 0;
        }
    },
    //获得月的第一天和最后一天时间戳
    getMonthStartAndEnd:function(myear,mmonth){
        var first=new Date();
        first.setFullYear(myear);
        first.setMonth(mmonth-1);
        first.setDate(1);
        var n = this.formatDateLong(first.getTime());
        var start = parseInt((new Date(n + " 00:00:00")).getTime() / 1000);
        var last = new Date(myear, mmonth,1);
        var n2 = this.formatDateLong(last.getTime()-24*3600*1000);
        var end = parseInt((new Date(n2 + " 23:59:59")).getTime() / 1000);
        return [start, end];
    },
    //获得月的第一天和最后一天时间戳
    getYearStartAndEnd: function (myear) {
        var first = new Date();
        first.setFullYear(myear);
        first.setMonth(0);
        first.setDate(1);
        var n = this.formatDateLong(first.getTime());
        var start = parseInt((new Date(n + " 00:00:00")).getTime() / 1000);
        var last = new Date(myear, 11, 31);
        var n2 = this.formatDateLong(last.getTime());
        var end = parseInt((new Date(n2 + " 23:59:59")).getTime() / 1000);
        return [start, end];
    },
    //获得今日00：00：00至23：59：59
    getTodayStartAndEndLong: function () {
        return this.getFullDayLong(this.now());
    },
    //获得今日00：00：00至23：59：59
    getTodayStartAndEnd: function () {
        return this.getFullDay(this.now());
    },
    showTimes:function(s){
        if(s>60&&s<3600){
            return (parseFloat(s)/parseFloat(60)).toFixed(2)+"分钟";
        }else if(s<=60){
            return s+"秒";
        }else if(s>=3600&&s<24*3600){
            return (parseFloat(s)/parseFloat(3600)).toFixed(2)+"小时";
        }else{
            return (parseFloat(s)/parseFloat(24*3600)).toFixed(2)+"天";
        }
    },
}