//首页
const front = [
    {
        path: '/',
        name: 'Login',
        component: (resolve) => require(['@/views/front/login.vue'], resolve)
    },
    {
        path: '/register',
        name: 'Register',
        component: (resolve) => require(['@/views/front/register.vue'], resolve)
    },
    {
        path: '/forgetpassword',
        name: 'ForgetPassword',
        component: (resolve) => require(['@/views/front/forgetpassword.vue'], resolve)
    },
    {
        //类目品牌搜索词
        path: '/test',
        name: 'Test',
        component: (resolve) => require(['@/views/front/test/test.vue'], resolve)
    },
    {
        //类目品牌搜索词
        path: '/downloadapk',
        name: 'DownloadApk',
        component: (resolve) => require(['@/views/front/downloadapk.vue'], resolve)
    },
]
export default front;