<template>
    <el-radio-group :disabled="disabled" v-model="avalue" @input="changeValue" @change="$emit('change')" :size="size">
        <el-radio-button :label="area.tag" :key="index" v-for="(area, index) in tableData">{{ area.name }}</el-radio-button>
    </el-radio-group>
</template>
<script>
export default {
    props: {
        disabled:{
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "mini",
        },
        isAll: {
            type: Boolean,
            default: true,
        }
    },
    watch:{
        value(newval){
            this.avalue = newval;
        }
    },
    data() {
        return {
            url: {
                getarealist: "/sysarea/getarealist",
            },
            avalue: "",
            where: {
                name: "",
                tag: "",
                sort_key: "",
                sort_value: "",
                page: 1,
                pageSize: 20000,
                total: 0,
                loading: false,
            },
            tableData: [],
        }
    },
    created() {
        this.avalue = this.value;
        this.loadList();
    },
    methods: {
        changeValue: function () {
            //this.$func.log(this.avalue);
            this.$emit("input", this.avalue);
        },
        loadList: function () {
            this.where.loading = true;
            this.$rq.postAction(this.url.getarealist, this.where).then((rs) => {
                this.where.loading = false;
                if (rs.code == 1) {
                    this.tableData = [];
                    if (this.isAll) {
                        this.tableData.push({
                            name: "全部",
                            tag: "",
                        });
                    }
                    for (var i in rs.list) {
                        this.tableData.push(rs.list[i]);
                    }
                    this.where.total = parseInt(rs.pageInfo.total);
                } else {
                    this.$message({
                        type: "warning",
                        message: rs.err,
                    });
                }
            });
        },
    }

}
</script>